<template>
  <div class="course-item-min">
    <div class="left">
      <div class="image-block">
        <img :src="recommendedVO.imageUrl" alt="image" class="main-image" v-if="recommendedVO.imageUrl"/>
        <div class="main-image" v-else></div>
      </div>
      <div class="course-info">
        <div class="title" v-clampy="1">{{recommendedVO.name}}</div>
        <ul class="info">
          <li>{{recommendedVO.type}}</li>
          <li>{{recommendedVO.reason}}</li>
        </ul>
      </div>
    </div>
    <div class="right">
      <b-button @click="visit(recommendedVO.id)" class="visit-btn">VISIT</b-button>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import RecommendedVO from "../../../vo/RecommendedVO";

  export default {
    name: 'RecommendedItem',
    props: {
      recommendedVO: {
        default: () => new RecommendedVO(),
        type: RecommendedVO
      }
    },
    computed: {
      ...mapGetters(["basePrimeApi", "primeAuth"])
    },
    methods: {
      visit(id) {
        window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/${id.replace(":", "/")}/overview`, "_self");
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .course-item-min
    height 90px
    /*width 100%*/
    display flex
    flex-direction row
    align-items center
    justify-content space-between

    .left
      display flex
      flex-direction row

      .image-block
        .main-image
          background-color rgba(0, 0, 0, 0.05)
          height 50px
          width 50px
          border-radius 100%

      .course-info
        display flex
        flex-direction column
        justify-content center
        margin-left 20px

        .title
          color #000000
          font-family "Open Sans"
          font-size 16px
          line-height 20px
          padding-right 10px

        .info
          padding 0
          margin 0
          line-height 17px

          li
            display inline
            list-style-type none
            font-family "Open Sans"
            font-size 12px
            line-height 20px
            color #000000
            opacity 0.6

            &:first-child
              opacity 1

            &:not(:first-child)::before
              content "•"
              display inline-block
              width 1em
              margin 0 5px
              text-align center
              font-size 15px
              line-height 14px
              vertical-align middle

    .right
      display flex
      flex-direction row

      .visit-btn
        height 30px
        width 60px
        margin-left 10px
        border-radius 4px
        background-color #006491
        color #F9F9F9
        font-family Univers67B
        font-size 10px
        letter-spacing 1.5px
        line-height 12px
        text-align center

  @media (max-width: 765px)
    .course-item-min
      /*width 100%*/

      .left
        .image-block
          .main-image
            height 40px
            width 40px

        .course-info
          margin-left 10px

      .right
        margin-top 15px

        .visit-btn
          height 15px
          width 30px
          border-radius 0
          border none
          background-color transparent
          color #006491
          font-size 12px
          letter-spacing 1.5px
          line-height 12px
          text-align center
          padding 0
          margin 0

</style>
