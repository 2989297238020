<template>
  <div class="dashboard-slider">
    <b-carousel
        :controls="showControls"
        :indicators="slides.length > 1"
        :interval="10000"
        background="#ababab"
        class="slider"
        img-height="480"
        img-width="1024"
        indicator-type="disc"
        style="text-shadow: 1px 1px 2px #333;"
        v-model="slide"
    >
      <b-carousel-slide :img-src="gateway + 'api/' + s.imageUrl" :key="s.order" v-for="s in slides">
        <div class="slider-inner-c">
          <div class="text-block">
            <div class="slide-title" v-clampy="2">{{s.header}}</div>
            <div class="slide-description" v-clampy="4">{{s.description}}</div>
            <b-button @click="onPrimaryClick(s)" class="primary-s-btn" v-if="s.primaryButtonLabel" variant="custom">
              {{s.primaryButtonLabel}}
              <div class="icon-after">
                <img alt="" class="icon" height="16" src="../assets/images/arrow-right.svg" width="16"/>
              </div>
            </b-button>
            <b-button @click="onSecondaryClick(s)" class="secondary-s-btn" v-if="s.secondaryButtonLabel" variant="custom">
              <div class="icon-before">
                <img alt="" class="icon" height="16" src="../assets/images/plus.svg" width="16"/>
              </div>
              {{s.secondaryButtonLabel}}
            </b-button>
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  import { mapGetters} from 'vuex';

  export default {
    name: 'DashboardSlider',
    data() {
      return {
        slide: 0,
        width: 0
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize() {
        this.width = window.innerWidth;
      },
      onPrimaryClick(s) {
        if (s.primaryActionIsCourse) {
          window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/${s.primaryActionUrl.replace(":", "/")}/overview`, "_self");
        } else {
          window.open(s.primaryActionUrl, "_self");
        }
      },
      onSecondaryClick(s) {
        if (s.secondaryActionIsCourse) {
          window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/${s.secondaryActionUrl.replace(":", "/")}/overview`, "_self");
        } else {
          window.open(s.secondaryActionUrl, "_self");
        }
      }
    },
    computed: {
      ...mapGetters(["slides", "gateway", "basePrimeApi", "primeAuth"]),
      showControls() {
        return this.width > 765 && this.slides.length > 1;
      }
    }
  }
</script>

<style>

  .img-fluid {
    height: 360px !important;
    overflow: hidden;
    text-align: center;
    object-fit: cover;
  }

  .slider-inner-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
  }

  .text-block {
    padding: 0 20%;
    margin: auto 0;
  }

  .carousel-control-prev, .carousel-control-next {
    height: 50px;
    width: 50px;
    margin: auto 15px;
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 0.7);
  }

  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
	  outline: none;
  }

  .carousel-indicators .active {
    width: 13px;
    height: 13px;
    border-radius: 100%;
  }

  .carousel-caption {
    position: absolute;
    color: #fff;
    text-align: left;
    width: auto;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    background: linear-gradient(90deg, #003851 0%, rgba(0, 0, 0, 0) 100%);
    padding: 0;
  }

  .slide-title {
    color: #FFFFFF;
    font-family: Univers67B;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
  }

  .slide-description {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 24px;
  }

  .primary-s-btn {
    color: #F9F9F9;
    font-family: Univers67B;
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
    height: 40px;
    background-color: #E90030;
    padding: 0;
    padding-left: 20px;
    padding-top: 2px;
    position: relative;
    margin-top: 20px;
    padding-right: 60px;
  }

  .secondary-s-btn {
    color: #F9F9F9;
    font-family: Univers67B;
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
    height: 40px;
    padding: 0;
    padding-left: 40px;
    padding-top: 0px;
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    background-color: transparent;
    border: transparent;
  }

  .secondary-s-btn:hover {
    background-color: transparent;
    color: #CCCCCC;
  }

  .primary-s-btn:hover {
    color: #CCCCCC;
  }

  .icon-after {
    width: 40px;
    height: 38px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D1002A;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .icon-before {
    width: 40px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  @media (max-width: 1350px) {
    .text-block {
      padding: 15%;
    }

    .slide-title {
      font-size: 36px;
      line-height: 40px;
    }
  }
  @media (max-width: 765px) {
    .carousel-caption {
      padding: 0 20px;
    }

    .slider-inner-c {
      margin: 0;
    }

    .text-block {
      padding: 0;
      margin: auto;
    }

  }

  .carousel-control-next, .carousel-control-prev {
    background-color: #fff;
    opacity: 1;
  }

  .carousel-control-next-icon {
    background-image: url('~@/assets/images/next.svg') !important;
    height: 15px;
  }

  .carousel-control-prev-icon {
    background-image: url('~@/assets/images/next.svg') !important;
    height: 15px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

</style>
