<template>
  <div class="course-item">
    <div class="image-block">
			<div class="main-image"  v-if="!learning.imageUrl"></div>
      <img v-else alt="image" class="main-image"  :src="learning.imageUrl"/>
			<div class="shadow-image"></div>
      <div class="num">{{learning.progressPercent ? learning.progressPercent : 0}}%</div>
      <b-progress :value="learning.progressPercent ? learning.progressPercent : 0" class="progress-line" variant="success"/>
    </div>
    <div class="block-l">
      <div class="course-info">
        <div class="title">{{learning.name}}</div>
        <ul class="info">
          <li @click="onTypeClick(learning)" style="cursor:pointer;">{{getType(learning)}}</li>
        </ul>
      </div>

      <div class="right">
        <div class="date">
          <div class="label">Due to</div>
          <div class="date-num">{{getDeadline(learning)}}</div>
        </div>
        <b-button class="visit-btn" @click="visit(learning.id)">VISIT</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LearningVO from '../../../vo/LearningVO'

export default {
	name: 'CourseItem',
	props: {
		learning: {
			default: () => new LearningVO(),
			type: LearningVO
		}
	},
	computed: {
		...mapGetters(['basePrimeApi', 'primeAuth'])
	},
	methods: {
		onTypeClick (t) {
			const qs = `certificationsSelected=${t.loType === 'certification'}&courseSelected=${t.loType === 'course'}&lpsSelected=${t.loType === 'learningProgram'}`;
			window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/catalog/index?myLearning=true&${qs}&myLearning=true&selectedSortOption=dueDate`, '_self')
		},
		getType (t) {
			if (t.loType === 'course') {
				return 'Course: ' + t.enrollmentType
			} else if (t.loType === 'learningProgram') {
				return 'Learning Program: ' + t.enrollmentType
			} else {
				return 'Certification'
			}
		},
		getDeadline (t) {
			if (t.hasDeadline) {
				if (t.loType === 'certification') {
					const d = parseInt(t.completionDeadline)
					return `${d} day${d > 1 ? 's' : ''}`
				}
				return `${this.$options.filters.formatDate(t.completionDeadline)}`
			}
			return 'No Deadline'
		},
		visit (id) {
			window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/${id.replace(':', '/')}/overview`, '_self')
		}
	}
}
</script>

<style lang="stylus" scoped>
  .course-item
    min-height 60px
    width 100%
    display flex
    flex-direction row
    align-items center

    .image-block
      position relative
      height max-content

      .main-image
        height 56px
        width 90px
        border-radius 3px

      .shadow-image
        height 56px
        width 90px
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
        position absolute
        border-radius 3px
        top 0

      .num
        position absolute
        bottom 5px
        left 5px
        color #FFFFFF
        font-family Univers67B
        font-size 16px
        line-height 20px
        text-align center

      .progress-line
        position absolute
        bottom 0
        left 0
        height 4px
        width 100%
        border-radius 0
        border-bottom-left-radius 3px
        border-bottom-right-radius 3px

    .block-l
      display flex
      flex-direction row
      width 100%
      justify-content space-between
      margin-left 20px

      .course-info
        display flex
        flex-direction column
        justify-content center
        width 70%

        .title
          color #000000
          font-family "Open Sans"
          font-size 16px
          line-height 20px
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

        .info
          padding 0
          margin 0
          line-height 17px

          li
            display inline
            list-style-type none
            font-family "Open Sans"
            font-size 12px
            line-height 20px
            color #000000
            opacity 0.6

            &:first-child
              opacity 1

            &:not(:first-child)::before
              content "•"
              display inline-block
              width 1em
              margin 0 5px
              text-align center
              font-size 15px
              line-height 14px
              vertical-align middle

      .right
        display flex
        flex-direction row
        width 30%
        justify-content space-between

        .date
          display flex
          flex-direction column
          margin-right 10px

          .label
            opacity 0.6
            color #000000
            font-family "Open Sans"
            font-size 12px
            line-height 7px

          .date-num
            color #000000
            font-family Univers57
            font-size 16px
            font-weight 500

        .visit-btn
          height 30px
          width 60px
          margin auto 0
          border-radius 4px
          background-color #006491
          color #F9F9F9
          font-family Univers67B
          font-size 10px
          letter-spacing 1.5px
          line-height 12px
          text-align center

  @media (max-width: 765px)
    .course-item
      height 90px

      .image-block
        height 65px

        .main-image
          height 61px

        .shadow-image
          height 61px

      .block-l
        flex-direction column
        height inherit
        height 65px

        .course-info
          width 100%

          .title
            font-size 16px

          .info
            li
              font-size 10px

              &:first-child
                font-size 12px

        .right
          justify-content space-between
          width 100%

          .date
            flex-direction row

            .label
              font-size 10px
              line-height 16px
              margin-right 5px

            .date-num
              font-size 12px

          .visit-btn
            height 15px
            width 30px
            border-radius 0
            border none
            background-color transparent
            color #006491
            font-size 12px
            letter-spacing 1.5px
            line-height 12px
            text-align center
            padding 0
</style>
