<template>
	<div id="my-skills">
		<div ref="blockRef" v-on-resize="checkWidth">
			<div class="title"><img alt="icon" class="block-icon" src="../../assets/images/skills.svg"/>My Skills</div>

			<div class="skills-c" v-if="filteredSkills.length">
				<div :key="skill.id" class="skills" v-for="skill in filteredSkills">
					<div class="skill">
						<div class="left">
							<span :class="['name', minLabels && 'min']">{{skill.name}}</span>
							<span class="badge">{{skill.levelName}}</span>
						</div>
						<div class="right">
							<span class="completed">{{skill.percentCompleted}}%</span>
							<span class="text">complete</span>
						</div>
					</div>
				</div>
			</div>
			<div class="empty-state" v-else>
				<img alt="icon" class="empty-state-icon" src="../../assets/images/empty_state_my_skills.svg"/>
				<div class="empty-state-text">Track Skills, their progress and explore further to Upskill yourselves.
				</div>
			</div>

		</div>
		<div class="see-all-block">
			<div @click="seeSkills" class="see-all-link">{{filteredSkills.length ? 'SEE ALL SKILL DATA' : "EXPLORE
				SKILLS"}}<img :style="{marginTop: '-2px'}"
				              src='../../assets/images/arrow-right-blue.svg'/></div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex';

	export default {
		name: 'MySkills',
		data() {
			return {
				minLabels: false,
				filteredSkills: []
			};
		},
		computed: {
			...mapGetters(['basePrimeApi', 'primeAuth', 'skills'])
		},
		methods: {
			...mapActions(['getMySkills']),
			seeSkills() {
				window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/skills`, '_self');
			},
			checkWidth() {
				this.minLabels = this.$refs.blockRef.clientWidth < 270;
			},
			loadSkills(next) {
				this.getMySkills(next).then(res => {
					let arr = this.skills.sort((a, b) => a.percentCompleted < b.percentCompleted ? 1 : -1);
          let low = arr.filter(s => s.percentCompleted < 100);
          let cent = arr.filter(s => s.percentCompleted === 100);
          this.filteredSkills = low.concat(cent).slice(0, 5);
        });
			}
		},
		created() {
			this.loadSkills();
		},
		directives: {
			onResize: {
				bind(el, binding, vnode) {
					el.resizeEl = function (el) {
						vnode.context[binding.expression](event);
					};
					window.addEventListener('resize', el.resizeEl);
				},
				unbind: function (el) {
					window.removeEventListener('click', el.resizeEl);
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.empty-state
		width 100%
		padding 40px
		display flex
		justify-content center
		align-items center
		flex-flow row nowrap
		text-align center

	.empty-state-text
		color #888
		font-family "Open Sans"
		font-size 14px
		line-height 20px
		max-width 190px
		padding-left 20px

	.empty-state-icon
		opacity 0.2

	#my-skills
		max-width 850px
		border-radius 10px
		background-color #FFFFFF
		box-shadow 0 5px 14px 0 rgba(0, 0, 0, 0.2)
		padding 30px 15px
		// margin-top 40px

		.title
			color #000000
			font-family Univers67B
			font-size 24px
			line-height 36px
			margin-bottom 20px
			padding 0 15px

			.block-icon
				margin-right 10px
				margin-top -3px

		.skills
			padding 0 15px

			.skill
				display flex
				flex-direction row
				justify-content space-between
				align-items center
				margin-bottom 20px

				.left
					display flex
					flex-direction row
					align-items center

					.name
						color #000000
						font-family "Open Sans"
						font-size 16px
						display flex
						align-items center
						margin-right 5px

						&.min
							width min-content

					.badge
						height 20px
						border-radius 10px
						background-color rgba(216, 216, 216, 0.2)
						opacity 0.5
						color rgba(0, 0, 0, 0.5)
						font-family "Open Sans"
						font-size 12px
						display flex
						align-items center

				.right
					display flex
					flex-direction row
					align-items center

					.completed
						color #000000
						font-family Univers67B
						font-size 14px
						text-align right
						display flex
						align-items center

					.text
						color #000000
						font-family Univers57
						font-size 14px
						text-align center
						display flex
						align-items center
						margin-left 5px

		.see-all-block
			border-top 1px solid #EAEAEA
			height 45px
			display flex
			justify-content flex-start
			padding-top 30px
			padding-left 30px
			width calc(100% + 30px)
			margin-left -15px

			.see-all-link
				color #006491
				font-family Univers67B
				font-size 12px
				letter-spacing 1px
				line-height 15px
				text-align center

	.see-all-link
		cursor pointer

		@media (max-width: 990px)
			#my-skills
				margin-top 20px

		@media (max-width: 765px)
			#my-skills
				.skills
					padding 0
</style>
