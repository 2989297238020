<template>
	<div class="custom-progress">
		<div :class="['block-1', propsActive === 0 ? 'active' : '']" :style="block1Style()"></div>
		<div :class="['block-2', propsActive === 1 ? 'active' : '']" :style="block2Style()"></div>
		<div :class="['block-3', propsActive === 2 ? 'active' : '']" :style="block3Style()"></div>
	</div>
</template>

<script>
export default {
	name: 'CustomProgress',
	data () {
		return {
			value: 100,
			max: 100
		}
	},
	methods: {
		randomValue () {
			this.value = Math.random() * this.max
		},
		block1Style: function () {
			const styles = {
				backgroundColor: this.elements[0].color,
				width: `calc(100% * ${this.elements[0].width})`,
				boxShadow: this.propsActive === 0 ? `0 2px 8px 0 ${this.elements[0].shadow}` : 'none'
			}
			if (this.elements[1].width === 0 && this.elements[2].width === 0) {
				styles.borderRadius = '18px'
			} else {
				styles.borderTopLeftRadius = '18px'
				styles.borderBottomLeftRadius = '18px'
			}
			return styles
		},
		block2Style: function () {
			const styles = {
				backgroundColor: this.elements[1].color,
				width: `calc(100% * ${this.elements[1].width})`,
				boxShadow: this.propsActive === 1 ? `0 2px 8px 0 ${this.elements[1].shadow}` : 'none'
			}
			if (this.elements[0].width === 0 && this.elements[2].width === 0) {
				styles.borderRadius = '18px'
			}
			return styles
		},
		block3Style: function () {
			const styles = {
				backgroundColor: this.elements[2].color,
				width: `calc(100% * ${this.elements[2].width})`,
				boxShadow: this.propsActive === 2 ? `0 2px 8px 0 ${this.elements[2].shadow}` : 'none'
			}
			if (this.elements[0].width === 0 && this.elements[1].width === 0) {
				styles.borderRadius = '18px'
			} else {
				styles.borderTopRightRadius = '18px'
				styles.borderBottomRightRadius = '18px'
			}
			return styles
		}
	},
	props: {
		elements: {
			type: Array
		},
		propsActive: {
			type: Number
		}
	}
}
</script>

<style lang="stylus" scoped>
.custom-progress
	display flex
	height 10px
	border-radius 18px
	background-color rgba(0,0,0,0.05)
	margin-bottom 10px

	.block-1
		background-color #FA5355
		width calc(100% * 0.5)
		height 10px
	.block-2
		background-color #FFBC16
		width calc(100% * 0.3)
		height 10px
	.block-3
		background-color #54BE54
		width calc(100% * 0.2)
		height 10px
	.active
		transform scaleY(1.5)
</style>
