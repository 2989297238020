<template>
	<div id="my-learning">
		<div>
			<div class="title"><img class="block-icon" src="../../assets/images/book.svg" alt="icon" />My Learning</div>
			<b-row class="m-0 p-0 desktop" >
				<b-col>
					<div class="block-title">By Deadline</div>
					<custom-progress :elements="deadlineProps" :propsActive="selectedLearningDeadlineIndex" />
					<div class="switchers">
						<lesson-type :active="showOverdue && filterSet" type="red" mainColor="#FA5455" :amount="overdue.length" title="Overdue" @click.native="applyDeadlineFilter(0)"></lesson-type>
						<lesson-type :active="showUpcoming && filterSet" type="yellow" mainColor="#FFBC15" :amount="upcoming.length" title="Upcoming" @click.native="applyDeadlineFilter(1)"/>
						<lesson-type :active="showOnTrack && filterSet" type="green" mainColor="#54BE53" :amount="onTrack.length" title="On track" @click.native="applyDeadlineFilter(2)"/>
					</div>
				</b-col>
				<b-col>
					<div class="block-title">Pending Learning</div>
					<custom-progress :elements="typeProps" :propsActive="selectedLearningTypeIndex" />
					<div class="switchers">
						<lesson-type :active="showCertifications && filterSet" type="blue-1" mainColor="#114D8A" :amount="certifications" title="Certifications" @click.native="applyTypeFilter(0)"/>
						<lesson-type :active="showLP && filterSet" type="blue-2" mainColor="#407EC1" :amount="lps" title="Learning Programs" @click.native="applyTypeFilter(1)"/>
						<lesson-type :active="showCourses && filterSet" type="blue-3" mainColor="#6CC2ED" :amount="courses" title="Courses" @click.native="applyTypeFilter(2)"/>
					</div>
				</b-col>
			</b-row>

			<b-row class="m-0 p-0 mobile">
				<div class="type-switcher">
					<div :class="['block-title', activeTabIndex===0 ? 'active' : '']" @click="setActiveTab(0)">
						By Deadline
					</div>
					<div :class="['block-title', activeTabIndex===1 ? 'active' : '']" @click="setActiveTab(1)">
						Pending Learning
					</div>
				</div>
				<custom-progress :style="{ width: '100%' }" :elements="deadlineProps" :propsActive="selectedLearningDeadlineIndex" v-if="activeTabIndex===0" />
				<custom-progress :style="{ width: '100%' }" :elements="typeProps" :propsActive="selectedLearningTypeIndex" v-if="activeTabIndex===1" />
				<div class="switchers" v-if="activeTabIndex===0">
						<lesson-type :active="showOverdue && filterSet" type="red" mainColor="#FA5455" :amount="overdue.length" title="Overdue" @click.native="applyDeadlineFilter(0)"></lesson-type>
						<lesson-type :active="showUpcoming && filterSet" type="yellow" mainColor="#FFBC15" :amount="upcoming.length" title="Upcoming" @click.native="applyDeadlineFilter(1)"/>
					  <lesson-type :active="showOnTrack && filterSet" type="green" mainColor="#54BE53" :amount="onTrack.length" title="On track" @click.native="applyDeadlineFilter(2)"/>
				</div>
					<div class="switchers" v-if="activeTabIndex===1">
						<lesson-type :active="showCertifications && filterSet" type="blue-1" mainColor="#114D8A" :amount="certifications" title="Certifications" @click.native="applyTypeFilter(0)"/>
						<lesson-type :active="showLP && filterSet" type="blue-2" mainColor="#407EC1" :amount="lps" title="Learning Programs" @click.native="applyTypeFilter(1)"/>
						<lesson-type :active="showCourses && filterSet" type="blue-3" mainColor="#6CC2ED" :amount="courses" title="Courses" @click.native="applyTypeFilter(2)"/>
					</div>
			</b-row>

			<b-row class="m-0 p-0 courses">
				<b-col class="course-item-c" v-if="filteredLearnings.length">
					<course-item class="item" v-for="l in filteredLearnings" :learning="l" :key="l.id"/>
				</b-col>
				<div class="empty-state" v-else>
					<img class="icon" src="../../assets/images/empty_state_learning.svg" alt="icon" />
					<div class="empty-state-title">Welcome to a new way of learning</div>
					<div class="empty-state-text">Enroll into new courses, upskill yourself, and earn badges, all in one place.</div>
				</div>
			</b-row>

		</div>
		<div class="see-all-block">
			<div class="see-all-link" @click="seeCourses">{{filteredLearnings.length ? 'SEE ALL MY LEARNING' : 'BROWSE THE CATALOG'}} <img :style="{marginTop: '-2px'}" src='../../assets/images/arrow-right-blue.svg'/></div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
	import CustomProgress from './components/CustomProgress'
	import LessonType from './components/LessonType'
	import CourseItem from './components/CourseItem'
	
	export default {
	name: 'MyLearning',
	components: {
		CustomProgress,
		LessonType,
		CourseItem
	},
	data: function () {
		return {
			deadlineProps: [
				{ color: '#FA5355', width: 0, shadow: 'rgba(250, 83, 85, 0.6)' },
				{ color: '#FFBC16', width: 0, shadow: 'rgba(255, 188, 22, 0.6)' },
				{ color: '#54BE54', width: 0, shadow: 'rgba(84, 190, 84, 0.6)' }
			],
			typeProps: [
				{ color: '#114D8A', width: 0, shadow: 'rgba(17, 77, 138, 0.6)' },
				{ color: '#407EC1', width: 0, shadow: 'rgba(64, 126, 193, 0.6)' },
				{ color: '#6CC2ED', width: 0, shadow: 'rgba(108, 194, 237, 0.6)' }
			],

			overdue: [],
			overdueWidth: 0,

			onTrack: [],
			onTrackWidth: 0,

			upcoming: [],
			upcomingWidth: 0,

			lps: 0,
			courses: 0,
			certifications: 0,

			// filtering flags
			showCertifications: false,
			showCourses: false,
			showLP: false,

			showOverdue: false,
			showUpcoming: false,
			showOnTrack: false,

			filterSet: false,

			selectedLearningDeadlineIndex: -1,
			selectedLearningTypeIndex: -1,

			activeTabIndex: 0
		}
	},
	computed: {
		...mapGetters(['basePrimeApi', 'primeAuth', 'learnings', 'lpStats', 'courseStats', 'certificationStats', "catalogId"]),
		filteredLearnings () {
			if (!this.showCertifications && !this.showCourses && !this.showLP && !this.showOverdue && !this.showUpcoming && !this.showOnTrack) {
				return this.learnings.sort((a, b) => a.progressPercent < b.progressPercent ? 1 : -1)
			} else {
				let arr = [];
				const all = this.overdue.concat(this.upcoming).concat(this.onTrack);
				if (this.showCertifications) {
					arr = this.learnings.filter(i => this.certificationStats.ids.indexOf(i.id) !== -1);
					arr = arr.filter(i => all.indexOf(i.id) !== -1)
				} else if (this.showCourses) {
					arr = this.learnings.filter(i => this.courseStats.ids.indexOf(i.id) !== -1);
					arr = arr.filter(i => all.indexOf(i.id) !== -1)
				} else if (this.showLP) {
					arr = this.learnings.filter(i => this.lpStats.ids.indexOf(i.id) !== -1);
					arr = arr.filter(i => all.indexOf(i.id) !== -1)
				} else if (this.showOverdue) {
					arr = this.learnings.filter(i => this.overdue.indexOf(i.id) !== -1)
				} else if (this.showUpcoming) {
					arr = this.learnings.filter(i => this.upcoming.indexOf(i.id) !== -1)
				} else if (this.showOnTrack) {
					arr = this.learnings.filter(i => this.onTrack.indexOf(i.id) !== -1)
				}
				return arr.sort((a, b) => a.progressPercent < b.progressPercent ? 1 : -1)
			}
		}

	},
	methods: {
		...mapActions(['getMyLearning', 'getMyMissedLearning' ,'getLearningStats', 'getCatalogContentEnrollment']),
		seeCourses () {
			if (this.learnings.length) {
				window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/catalog/index?myLearning=true&selectedListableCatalogIds=${this.catalogId}&selectedCategories=%22%22&selectedSortOption=dueDate&selectedTags=%22%22`, '_self')
			} else {
				window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/catalog/index?selectedListableCatalogIds=${this.catalogId}&selectedCategories=%22%22&selectedSortOption=-date&selectedTags=%22%22`, '_self');
			}
		},
		setActiveTab (idx) {
			this.activeTabIndex = idx;
			this.resetFilters()
		},
		resetFilters () {
			this.showOnTrack = this.showUpcoming = this.showOverdue = this.showCourses = this.showCertifications = this.showLP = false;
			this.showOnTrack = this.showUpcoming = this.showOverdue = this.showLP = this.showCertifications = this.showCourses = false;
			this.selectedLearningDeadlineIndex = this.selectedLearningTypeIndex = -1;
			this.filterSet = false
		},
		applyTypeFilter (idx) {
			if (idx === 0) {
				this.showCertifications = !this.showCertifications;
				this.showOnTrack = this.showUpcoming = this.showOverdue = this.showCourses = this.showLP = false;
				this.filterSet = this.showCertifications
			} else if (idx === 1) {
				this.showLP = !this.showLP;
				this.showOnTrack = this.showUpcoming = this.showOverdue = this.showCourses = this.showCertifications = false;
				this.filterSet = this.showLP
			} else if (idx === 2) {
				this.showCourses = !this.showCourses;
				this.showOnTrack = this.showUpcoming = this.showOverdue = this.showCertifications = this.showLP = false;
				this.filterSet = this.showCourses
			}
			this.selectedLearningDeadlineIndex = -1;
			this.selectedLearningTypeIndex = this.filterSet ? idx : -1
		},
		applyDeadlineFilter (idx) {
			if (idx === 0) {
				this.showOverdue = !this.showOverdue;
				this.showOnTrack = this.showUpcoming = this.showLP = this.showCertifications = this.showCourses = false;
				this.filterSet = this.showOverdue
			} else if (idx === 1) {
				this.showUpcoming = !this.showUpcoming;
				this.showOnTrack = this.showOverdue = this.showLP = this.showCertifications = this.showCourses = false;
				this.filterSet = this.showUpcoming
			} else if (idx === 2) {
				this.showOnTrack = !this.showOnTrack;
				this.showUpcoming = this.showOverdue = this.showLP = this.showCertifications = this.showCourses = false;
				this.filterSet = this.showOnTrack
			}
			this.selectedLearningTypeIndex = -1;
			this.selectedLearningDeadlineIndex = this.filterSet ? idx : -1
		},
		getNextMyLearning (next) {
			this.getMyLearning(next).then(data => {
				if (data) {
					if (data.links.next) {
						this.getNextMyLearning(data.links.next)
					} else {
						this.loadStatistic();
					}
				}
			})
		},
		loadDetails () {
			this.learnings.forEach(content => {
				if (!content.enrollmentsDetailsLoaded) {
					this.getCatalogContentEnrollment(content)
				}
			})
		},
		checkMissed() {
			const lids = this.learnings.map(l=> l.id);
			const missed = [];
			const allStats = this.overdue.concat(this.upcoming).concat(this.onTrack);
			allStats.forEach(i => {
				if(lids.indexOf(i) === -1) missed.push(i)
			});
			if (missed.length) {
				this.loadMissed(missed);
			} else {
				this.loadDetails();
			}
		},
		loadMissed(ids) {
			const chunk = ids.splice(0, 10);
			this.getMyMissedLearning(chunk).then(result => {
				if (result && ids.length) {
					this.loadMissed(ids);
				} else {
					this.loadDetails();
				}
			})
		},
		loadStatistic() {
			this.getLearningStats().then(data => {
				this.overdue = this.lpStats.overdue.concat(this.courseStats.overdue).concat(this.certificationStats.overdue);
				this.upcoming = this.lpStats.due.concat(this.courseStats.due).concat(this.certificationStats.due);
				this.onTrack = this.lpStats.safe.concat(this.courseStats.safe).concat(this.certificationStats.safe);
				
				let sum = this.overdue.length + this.upcoming.length + this.onTrack.length;
				
				this.deadlineProps[0].width = this.overdue.length / sum;
				this.deadlineProps[1].width = this.upcoming.length / sum;
				this.deadlineProps[2].width = this.onTrack.length / sum;
				
				this.lps = this.lpStats.due.length + this.lpStats.safe.length + this.lpStats.overdue.length;
				this.courses = this.courseStats.due.length + this.courseStats.safe.length + this.courseStats.overdue.length;
				this.certifications = this.certificationStats.due.length + this.certificationStats.safe.length + this.certificationStats.overdue.length;
				
				sum = this.lps + this.courses + this.certifications;
				
				this.typeProps[0].width = this.certifications / sum;
				this.typeProps[1].width = this.lps / sum;
				this.typeProps[2].width = this.courses / sum;
				
				this.checkMissed();
			})
		}
	},
	created () {
		if (!this.learnings.length) {
			this.getNextMyLearning();
		}
	}
}
</script>

<style lang="stylus" scoped>
	.course-item-c
		max-height 870px
		overflow-y auto

	@media (max-width: 765px)
		.course-item-c
			max-height 1050px

	.empty-state
		width 100%
		padding 40px
		display flex
		justify-content center
		align-items center
		flex-flow column nowrap
		text-align center

	.empty-state-title
		color #000000
		font-family Univers57
		font-size 22px
		font-weight 500
		line-height 36px
		padding 20px

	.empty-state-text
		color #888
		font-family "Open Sans"
		font-size 14px
		line-height 20px
		max-width 300px
		padding 20px


	#my-learning
		border-radius 10px
		background-color #FFFFFF
		box-shadow 0 5px 14px 0 rgba(0, 0, 0, 0.2)
		padding 30px 15px

		.title
			color #000000
			font-family Univers67B
			font-size 24px
			line-height 36px
			margin-bottom 20px
			padding 0 15px

			.block-icon
				margin-right 10px
				margin-top -3px

		.block-title
			margin-bottom 10px
			color #000000
			font-family Univers57
			font-size 22px
			font-weight 500
			cursor pointer

		.switchers
			display flex
			flex-direction row
			margin-top 10px

			.l-type
				&:not(:last-child)
					margin-right 20px

		.courses
			margin-top 30px !important

		.item
			border-bottom 1px solid #EAEAEA
			padding-top 5px
			padding-bottom 10px
			margin-bottom 15px
			padding-right 15px

			&:last-child
				border-bottom 0

		.see-all-block
			border-top 1px solid #EAEAEA
			height 45px
			display flex
			justify-content flex-start
			padding-top 30px
			padding-left 30px
			width calc(100% + 30px)
			margin-left -15px

			.see-all-link
				color #006491
				font-family Univers67B
				font-size 12px
				letter-spacing 1px
				line-height 15px
				text-align center
	.see-all-link
		cursor pointer

	.desktop
		display flex

	.mobile
		display none

	@media (max-width: 1385px)
		#my-learning
			padding 30px 15px

			.desktop
				display none

			.mobile
				display flex
				flex-direction column

			.courses
				.col
					padding 0 !important

			.title
				padding 0

			.type-switcher
				display flex
				flex-direction row
				margin-bottom 15px

				.block-title
					opacity 0.6
					font-size: 22px

					&.active
						opacity 1
						border-bottom 2px solid #000

					&:first-child
						margin-right 25px

	@media (max-width: 360px)
		.switchers
			.l-type
				&:not(:last-child)
					margin-right 0 !important

</style>
