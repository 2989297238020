<template>
	<div :class="['l-type', active ? 'active' : '', type]">
		<div class="num" :style="{backgroundColor: mainColor}">{{amount}}</div>
		<span class="title">{{title}}</span>
	</div>
</template>

<script>
export default {
	name: 'LessonType',
	data: function () {
		return {

		}
	},
	props: {
		type: {
			type: String
		},
		active: {
			type: Boolean
		},
		mainColor: {
			type: String
		},
		title: {
			type: String
		},
		amount: {
			type: Number
		}
	}
}
</script>

<style lang="stylus" scoped>
	.l-type
		height 65px
		border-radius 5px
		background-color rgba(0,0,0,0.05)
		width max-content
		padding 10px
		position relative
		min-width 80px
		cursor pointer
		&.active
			position relative
			.title
				font-weight 700
			&.red
				background #f9d1d1
				border 1px solid #FA5455 // border
				&::before
					border-color #f9d1d1 transparent
				&::after
					border-color #FA5455 transparent // border
			&.green
				background #EDF8ED
				border 1px solid rgba(84,190,84,0.15) // border
				&::before
					border-color #EDF8ED transparent
				&::after
					border-color rgba(84,190,84,0.15) transparent // border
			&.yellow
				background #fceabf
				border 1px solid #FFBC15 // border
				&::before
					border-color #fceabf transparent
				&::after
					border-color #FFBC15 transparent // border

			&.blue-1
				background #5c8cbc
				border 1px solid #114D8A // border
				&::before
					border-color #5c8cbc transparent
				&::after
					border-color #114D8A transparent // border
			&.blue-2
				background #88b3e0
				border 1px solid #407EC1 // border
				&::before
					border-color #88b3e0 transparent
				&::after
					border-color #407EC1 transparent // border

			&.blue-3
				background #c4e9fc
				border 1px solid #6CC2ED // border
				&::before
					border-color #c4e9fc transparent
				&::after
					border-color #6CC2ED transparent // border

			&::after,
			&::before
				content ''
				position absolute
				border-style solid
				border-width 10px 10px 0
				display block
				width 0
				z-index 1
				bottom -10px
				height 0
				margin 0 auto
				left 0
				right 0
			&::before
				z-index 5
				bottom -9px

		.num
			height 24px
			border-radius 5px
			background-color #54BE53
			padding 4px 8px
			width fit-content
			color #FFFFFF
			font-family Montserrat
			font-size 12px
			font-weight bold
			text-align center
			margin-bottom 3px
		.title
			color #000000
			font-family "Open Sans"
			font-size 14px
			margin-top 10px

@media (max-width: 765px)
	.l-type
		.title
			font-size 12px
</style>
