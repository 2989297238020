<template>
  <div>
    <b-row class="m-0" :style="{'backgroundColor': !slides.length && brand ? brand.color : 'none'}">
      <b-col class="m-0 p-0" md="4" xs="12" v-if="brand" :lg="slides.length ? 3 : 4">
        <div class="greeting" :style="{backgroundColor: brand.color}">
          <img class="image" v-if="brand.imageUrl"
               :src="`${gateway}api/${brand.imageUrl}`"/>
          <span class="text" v-clampy="10">{{brand.description}}</span>
        </div>
      </b-col>
      <b-col class="m-0 p-0" :md="brand ? 8 : 12" xs="12" :lg="brand ? 9 : 12" v-if="slides.length">
        <dashboard-slider/>
      </b-col>
    </b-row>
    <b-row class="m-0 page-content">
      <b-col class="mobile-no-padding" lg="8" xs="12">
        <my-learning/>
        <recommended/>
      </b-col>
      <b-col>
        <my-skills/>
<!--        <my-jobs-aids/>-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardSlider from '../components/DashboardSlider.vue';
import MyLearning from '../components/dashboard/MyLearning.vue';
import Recommended from '../components/dashboard/Recommended.vue';
import MySkills from '../components/dashboard/MySkills.vue';
// import MyJobsAids from '../components/dashboard/MyJobsAids.vue';

export default {
	name: 'Dashboard',
	components: {
		DashboardSlider,
		MyLearning,
		Recommended,
		MySkills,
		// MyJobsAids
	},
	computed: {
		// TODO:change layout if brand is null
		...mapGetters(['brand', 'gateway', 'slides'])
	},
	methods: {
		...mapActions(['getSlides']),
		...mapActions('notifications', ['getNotifications'])
	},
	created () {
		this.getSlides();
		this.getNotifications();
	}
};
</script>

<style lang="stylus" scoped>
  .greeting
    background-color #005177
    height 360px
    display flex
    justify-content center
    align-items center
    flex-direction column
    padding 0 60px

    .image
      height 75px
      width 75px
      margin-bottom 30px
      object-fit contain

    .text
      color #FFFFFF
      font-family "Open Sans"
      font-size 16px
      line-height 24px
      text-align center

  .page-content
    padding 40px 80px

  @media (max-width: 765px)
    .page-content
      padding 0 0 20px 0

      .mobile-no-padding
        padding 0

  @media (max-width: 425px)
    .greeting
      height 150px
      padding 0 20px

      .image
        height 54px
        width 54px
        margin-bottom 15px

      .text
        font-size 12px
        line-height 16px

</style>
