import { render, staticRenderFns } from "./Recommended.vue?vue&type=template&id=6b96aa9b&scoped=true&"
import script from "./Recommended.vue?vue&type=script&lang=js&"
export * from "./Recommended.vue?vue&type=script&lang=js&"
import style0 from "./Recommended.vue?vue&type=style&index=0&id=6b96aa9b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b96aa9b",
  null
  
)

export default component.exports