<template>
  <div class="recommended">
    <div>
      <div class="title"><img alt="icon" class="block-icon" src="../../assets/images/book.svg"/>Recommended</div>

      <div class="rec-c" v-if="recommended.length">
        <recommended-item :class="{'rec': recommended.length > 1, 'rec-lonely': recommended.length === 1}"
                          :key="r.id"
                          :recommendedVO="r" v-for="r in recommended"/>
      </div>
      <div v-else class="empty-state">
        <img alt="icon" class="empty-state-icon" src="../../assets/images/empty_state_recommended.svg"/>
        <div class="empty-state-text">You have no Recommendations.</div>
      </div>
    </div>
    <div class="see-all-block" v-if="recommended.length !== 1">
      <div @click="seeCatalog" class="see-all-link">{{recommended.length ? "SEE ALL RECOMMENDED" : "BROWSE THE CATALOG"}} <img :style="{marginTop: '-2px'}" src='../../assets/images/arrow-right-blue.svg'/></div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import RecommendedItem from './components/RecommendedItem.vue'

  export default {
    name: 'Recommended',
    data: function () {
      return {}
    },
    methods: {
      ...mapActions(['getRecommended']),
      seeCatalog() {
        window.open(`${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/catalog/index?notenrolledSelected=true&selectedSortOption=-date`, '_self')
      }
    },
    computed: {
      ...mapGetters(['recommended', 'basePrimeApi', 'primeAuth'])
    },
    components: {
      RecommendedItem
    },
    created() {
      this.getRecommended()
    }
  }
</script>

<style scoped>

  .empty-state {
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    text-align: center;
  }

  .empty-state-text {
    color: #888;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
  }

  .empty-state-icon {
    opacity: 0.2;
  }

  .recommended {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin: 40px 0;
  }

  .title {
    color: #000000;
    font-family: Univers67B;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0 15px;
  }

  .block-icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .rec-c {
    display: flex;
    max-height: 404px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .rec {
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 50%;
    max-width: 48%;
  }

  .rec-lonely {
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .see-all-block {
    border-top: 1px solid #EAEAEA;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    padding-top: 30px;
    padding-left: 30px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .see-all-link {
    color: #006491;
    font-family: Univers67B;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: 1400px) {
    .rec {
      width: 100%;
      max-width: 100%;
    }

    .rec-c {
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  @media (max-width: 765px) {
    .recommended {
      margin-left: 15px;
      margin-right: 15px;
      padding: 30px 20px 30px 30px;
    }

    .rec, .rec-lonely {
      padding: 10px;
      max-width: 100% !important;
      width: 100%;
    }

  }
</style>
